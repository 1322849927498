import React from 'react';
import {Link, Text, View} from "@react-pdf/renderer";
import {SocialIcon} from "../../Icons";
import {withTranslation} from "react-i18next";
import {ImageRender} from "../../Images";
import {SupportService} from "../../../../support";

const Preferences = ({t, data}) => {
  const {preferences} = data;

  return (
    <View>
      <Text style={{marginBottom: '20px', fontFamily: 'pdfFont',}}>{t("Preferences")}</Text>

      {
        preferences.map(({preferences, sid}, idx) => {
          return preferences.map(({title, items = [], type = 'cards'}, i) =>
            <View key={i} style={{
              display: 'flex',
              flexFlow: 'row',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              padding: '10px'
            }}>
              <View style={{width: '100px', marginRight: '20px', flexShrink: 0}}>
                <Text style={{fontSize: '10px', fontFamily: 'pdfFont',}}>{title}:</Text>
              </View>

              <View style={{
                display: 'flex',
                flexFlow: 'row',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
                {
                  type === 'cards' && items.map(({img = null, subTitle = null, url = null}, k) => <View
                      key={k}
                      style={{
                        width: '150px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginRight: '10px',
                        marginBottom: '10px',
                        overflow: 'hidden',
                      }}>
                      <View>
                        {ImageRender(img)}
                      </View>
                      <View>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                          <Link src={url} style={{display: 'flex', flexDirection: 'row'}}>
                            <SocialIcon network={sid}/>
                            <Text style={{fontSize: '8px', fontFamily: 'pdfFont',}}>{subTitle}</Text>
                          </Link>
                        </View>
                      </View>
                    </View>
                  )
                }
                {
                  type === 'timeline' && items.map((item, idx) => <View
                    key={idx}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      marginRight: '10px',
                      marginBottom: '10px',
                      overflow: 'hidden',
                    }}>
                    {
                      Object.keys(item).map((oneKey,itemIdx) => {
                        return (
                          <View key={itemIdx} style={{display: 'flex', flexFlow: 'row', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginRight: '10px'}}>
                            <Text style={{fontSize: '8px', fontWeight: 'bold', marginRight: '10px', fontFamily: 'pdfFont', width: '100px',}}>{SupportService.getTitle(oneKey)}:</Text>
                            <Text style={{fontSize: '8px', fontFamily: 'pdfFont', width: '200px', display: 'inline',}}>{item[oneKey]}</Text>
                          </View>
                        )
                      })
                    }
                  </View>)
                }
              </View>
            </View>
          )
        })
      }
    </View>
  )
};

export default withTranslation()(Preferences);