import {
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  HeadingLevel,
  Footer,
  AlignmentType,
  PageNumber, TabStopType, TabStopPosition, Media, WidthType, BorderStyle, ImageRun
} from "docx";
import i18n from "i18next";
import {imageByIndex} from "../../../../helpers/imageByIndex";
import {EXT_TITLE, VERSION} from "../../../../config/consts";

import noAvatar from "../../../../assets/img/dummy/noavatar1.png";
import config from "../../../../config/config";

class DocxService {
  static dimensions = {};
  static createFooter = () => {
    return {
      footers: {
        default: new Footer({
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun(` ${i18n.t("Created by")} ${config.appTitle || EXT_TITLE} ${VERSION} `),
                new TextRun({
                  children: [i18n.t("Page") + ": ", PageNumber.CURRENT],
                }),
                new TextRun({
                  children: [" " + i18n.t("of") + " ", PageNumber.TOTAL_PAGES],
                }),
              ],
            }),
          ],
        }),
      },
    }
  }
  static createColumns = (textColumns, position = 1000) =>  {
    const columns = [];
    textColumns.forEach((col, key) => {
      if (col.text && key > 0) {
        col.text = `\t${col.text}`;
      }
      columns.push(new TextRun(col));
    });

    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.LEFT,
          //position: docx.TabStopPosition.MAX,
          position,
        },
      ],
      children: columns,
    })
  }
  static createAlignedColumns = (leftText, rightText) => {
    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.RIGHT,
          position: TabStopPosition.MAX,
        },
      ],
      children: [
        new TextRun({
          text: leftText,
          bold: true,
        }),
        new TextRun({
          text: `\t${rightText}`,
          bold: true,
        }),
      ],
    });
  }
  static createHeading = text => {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true,
    });
  }
  static createSubHeading = text => {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_4,
      thematicBreak: true,
    });
  }
  static createEmptySpace = () => {
    return new Paragraph({
      text: ' ',
      heading: HeadingLevel.HEADING_1,
    });
  }
  static createEmptyRow = () => {
    return new Paragraph({
      text: ' ',
    });
  }
  static createTable = (rows = [], options = {width: [70, 30]}) => {
    const sourceRows = [];
    const margins = {
      top: 60,
      bottom: 0,
      left: 0,
      right: 0,
    };
    const borders = {
      ttop: {style: BorderStyle.SINGLE, size: 0, color: "000000"},
      bottom: {style: BorderStyle.SINGLE, size: 0, color: "000000"},
      left: {style: BorderStyle.SINGLE, size: 0, color: "000000"},
      right: {style: BorderStyle.SINGLE, size: 0, color: "000000"},
    }
    rows.forEach(({header, value}) => {
      sourceRows.push(new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({children: [ new TextRun({text: header || '', bold: true})]})],
            width: {
              size: options?.width?.[0] || 70,
              type: WidthType.PERCENTAGE,
            },
            borders,
            margins,
          }),
          new TableCell({
            children: [new Paragraph(value || '')],
            width: {
              size: options?.width?.[1] || 30,
              type: WidthType.PERCENTAGE,
            },
            borders,
            margins,
          })
        ],
      }))
    });
    return new Table({
      rows: sourceRows,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: {style: BorderStyle.SINGLE, size: 0, color: "000000"},
        bottom: {style: BorderStyle.SINGLE, size: 0, color: "000000"},
        left: {style: BorderStyle.SINGLE, size: 0, color: "000000"},
        right: {style: BorderStyle.SINGLE, size: 0, color: "000000"},
      },
    });
  }
  static createImage = (src, options, internal = true) => {
    if (!src?.trim()) {
      //return new TextRun(' ');
      src = noAvatar;
    }

    //src = noAvatar;

    /*const blob = fetch(internal ? imageByIndex(src) : src)
      .then(r => r?.blob());*/

    try {
      const img = fetch(internal ? imageByIndex(src) : src).then(r => {
        //console.log('r', r);
        if (r.ok) {
           return r.blob();
        } else {
          return fetch(noAvatar).then(r => r.blob());
        }
      }).catch(e => {
        console.log('e', e, src);
        return fetch(noAvatar).then(r => r.blob());
      });
      //console.log('img', src);
      return new ImageRun({
        data: img,
        transformation: {
          width: options?.width || 370,
          height: options?.height || 370
        }
      });

    } catch (e) {
      //console.log('ee', e);
    }

    //const fs = require('fs');

    /*let blob;

    try {
      blob = fetch(internal ? imageByIndex(src) : src)
        .then(r => r.blob());

      const ir = new ImageRun({
        data: blob,
        transformation: {
          width: options?.width || 370,
          height: options?.height || 370
        }
      })
      return ir;
    } catch (e) {
      console.log('e', e);
    }*/



    /*try {
      let blob = null;
      try{
        blob = fetch(internal ? imageByIndex(src) : src)
          .then(r => {
            try{
              return r?.blob()
            } catch (eee) {
              console.log('eee', eee, src);
              return new TextRun({text: ' '});
            }
          })
          .catch(e => {
            console.log('e', e, src);
            return new TextRun({text: ' '});
          });
      } catch (ee) {
        console.log('ee', ee);
        return new TextRun(' ');
      }
      console.log('continue,', src);

      //return Media.addImage(doc, blob, options?.width || 370, options?.height || 370, {});
      if (blob) {
        try {
          const ir = new ImageRun({
            data: blob,
            transformation: {
              width: options?.width || 370,
              height: options?.height || 370
            }
          })
          return ir;
        } catch (eeee) {
          console.log('eeee', eeee);
          return new TextRun(' ');
        }
      } else {
        return new TextRun({text: ' '});
      }
    } catch (e) {
      console.log('createImage error:', e);
      return new TextRun(' ');
    }*/
  }
  static fitImage = (doc, src, base64images, targetWidth, targetHeight) => {
    //console.log('base64images', base64images);
    const base64image = id => {
      return base64images.find(b => b.id === id);
    }
    //const image = fetch(imageByIndex(src)).then(r => r.blob());
    //console.log('sizeOf', imageSize(imageByIndex(src)));
    //return false;
    //const originalSize = imageSize(image);

    try{
      const {base64data: image, size: originalSize} = base64image(src);
      const originalAspectRatio = originalSize.width / originalSize.height;

      let width;
      let height;

      if (!targetWidth) {
        // fixed height, calc width
        height = targetHeight;
        width = height * originalAspectRatio;
      } else if (!targetHeight) {
        // fixed width, calc height
        width = targetWidth;
        height = width / originalAspectRatio;
      } else {
        const targetRatio = targetWidth / targetHeight;
        if (targetRatio > originalAspectRatio) {
          // fill height, calc width
          height = targetHeight;
          width = height * originalAspectRatio;
        } else {
          // fill width, calc height
          width = targetWidth;
          height = width / originalAspectRatio;
        }
      }

      try {
        return Media.addImage(doc, image, width, height);
      } catch (e) {}

    } catch (e) {}
    //console.log(originalSize, originalAspectRatio, width, height);
  }

}

export default DocxService;