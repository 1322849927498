import BaseReducer from "../BaseReducer";
import DotsAction from "./dotsAction";

export default class DotsReducer extends BaseReducer {
  initialState = {
    nodesLoading: false,
    nodes: [],
    browserMode: 'regular', //dots
    selectedTargets: [],
    modalActive: null,
    modalData: {},
    iteration: 0,
  };

  /* SET ANY PROPERTY TO Dots store*/
  [DotsAction.UPDATE_DOTS_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    }
  }

  [DotsAction.UPDATE_NODE_DATA](state, action) {
    return {
      ...state,
      nodes: {
        ...state.nodes,
        nodes: [
          ...state.nodes?.nodes?.map(node => node.id === action.payload.id
            ? {...node, data: {...node.data, ...action.payload.data}}
            : node
          ),
        ]
      },
    }
  }

}
