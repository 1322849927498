import ActionUtility from "../ActionUtility";

export default class DotsAction {
  static UPDATE_DOTS_STATE = "DotsAction.UPDATE_DOTS_STATE";
  static GET_NODES = "DotsAction.GET_NODES";
  static GET_SOURCE_INFO = "DotsAction.GET_SOURCE_INFO";
  static GET_TARGET_NODES = "DotsAction.GET_TARGET_NODES";

  static GET_BROWSER_MODE = "DotsAction.GET_BROWSER_MODE";
  static SET_BROWSER_MODE = "DotsAction.SET_BROWSER_MODE";

  static UPDATE_NODE_DATA = "DotsAction.UPDATE_NODE_DATA";



  static updateDotsState(action) {
    return ActionUtility.createAction(DotsAction.UPDATE_DOTS_STATE, action);
  }

  /* Nodes */
  static getNodes(caseId, selectedTargets = [], silent = false) {
    return ActionUtility.createAction(DotsAction.GET_NODES, {caseId, selectedTargets, silent});
  }

  static getSourceInfo(targetId, lookupRequest, callback) {
    return ActionUtility.createAction(DotsAction.GET_SOURCE_INFO, {targetId, lookupRequest, callback});
  }

  static getTargetNodes(target) {
    return ActionUtility.createAction(DotsAction.GET_TARGET_NODES, target);
  }

  /* Browser Mode */
  static getBrowserMode() {
    return ActionUtility.createAction(DotsAction.GET_BROWSER_MODE);
  }

  static setBrowserMode(mode) {
    return ActionUtility.createAction(DotsAction.SET_BROWSER_MODE, mode);
  }

  static updateNodeData(id, data) {
    return ActionUtility.createAction(DotsAction.UPDATE_NODE_DATA, {id, data});
  }

}
