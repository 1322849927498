import moment from "moment";
import {imageByIndex} from "../../../../helpers/imageByIndex";
import {SupportService} from "../../../support";
import i18n from "i18next";

export class ExportXlsProfiler {
  static exportPerson = ({person, personalInfo}) => {
    const data = [
      ['ID', person.id],
      [i18n.t('Created Date'), moment(person.createdDate, 'x').format("YYYY/MM/DD HH:mm")],
      [i18n.t('Phone'), person.phone],
      [i18n.t('Name'), person.name],
      [i18n.t('Country'), personalInfo.countryCode],
      [i18n.t('Likes'), person.commonStatistics.likes],
      [i18n.t('Friends'), person.commonStatistics.friends],
      [i18n.t('Mentions'), person.commonStatistics.mentions],
      [i18n.t('Posts'), person.commonStatistics.posts],

    ];

    return data;
  }

  static exportFoundNames = ({personalInfo}) => {
    const data = [
      [i18n.t('Social network'), i18n.t('Name')],
    ];

    personalInfo?.names?.forEach(({network, name}) => {
      data.push([network, name]);
    })

    return data;
  }

  static exportEmails = ({personalInfo}) => {
    const data = [
      [i18n.t('Social network'), 'Email'],
    ];

    personalInfo?.emails?.forEach(({source, data: email}) => {
      data.push([source, email]);
    })

    return data;
  }

  static exportSocialNetworks = ({personalInfo}) => {
    const data = [
      [i18n.t('Social network'), i18n.t('Profile')],
    ];

    personalInfo?.networks?.forEach(({sid, profile}) => {
      data.push([sid, profile]);
    })

    return data;
  }

  static exportPsychoProfiles = ({psychoProfile}) => {
    let psychoItems = [];
    let summaryItems = [];

    if (psychoProfile) {
      const { summary = {}, psycho = {} } = psychoProfile;
      summaryItems = summary?.result?.summaries || [];
      psychoItems = psycho?.result?.profiles || [];
    }
    const data = [
      [i18n.t('Found Info'), i18n.t('Value')],
    ];

    psychoItems?.forEach(({personName, psychologicalPortrait, levelOfDanger, predictedCharacteristics}, idx) => {
      data.push([i18n.t("Name"), personName]);
      data.push([i18n.t("Portrait"), psychologicalPortrait]);
      data.push([i18n.t("Level of Danger"), levelOfDanger]);
      data.push([i18n.t("Predicted Characteristics"), predictedCharacteristics?.join(", ")]);
      data.push(['', '']);
    })

    summaryItems?.forEach(({summary, country, age, occupation, gender}, idx) => {
      data.push([i18n.t("Summary"), summary]);
      data.push([i18n.t("Country"), country]);
      data.push([i18n.t("Age"), age]);
      data.push([i18n.t("Occupation"), occupation]);
      data.push([i18n.t("Gender"), gender]);
      data.push(['', '']);
    })

    return data;
  }

  static exportLocations = ({locations = []}) => {
    const data = [
      [i18n.t('Country'), i18n.t('City')],
    ];

    locations?.forEach(({country, city}) => {
      data.push([country, city]);
    })

    return data;
  }

  static exportFriends = ({friends = []}) => {
    const data = [
      ['ID', i18n.t('Social Network'), i18n.t('Name')],
    ];

    friends?.forEach(({friendProfileId, socialNetwork, name}) => {
      data.push([friendProfileId, socialNetwork, name]);
    })

    return data;
  }

  static exportImagResults = ({imageSearchResults}) => {
    const data = [
      [i18n.t('Profile'), i18n.t("Profile URL"), i18n.t('Name'), i18n.t('Location'), i18n.t('Image'), i18n.t('Source')],
    ];

    imageSearchResults?.imageResults?.forEach(res => {
      data.push([
        res?.profile,
        res?.profileUrl,
        res?.name,
        res?.location,
        res?.avatar,
        res?.profiles?.[0]?.sn,
      ]);
    })

    return data;
  }

  static exportImages = ({images = []}) => {
    const data = [
      [i18n.t('Source'), i18n.t('Image URL')],
    ];

    images?.forEach(({source, imageId}) => {
      data.push([source, imageByIndex(imageId)]);
    })

    return data;
  }

  static exportWebSearch = ({webResults = []}) => {
    const data = [
      [i18n.t('Source'), i18n.t('Keyword'), i18n.t('Title'), i18n.t('Text'), i18n.t('Link')],
    ];

    webResults?.forEach(({searchEngine, keyword, resultTitle, resultPreview, resultLink}) => {
      data.push([searchEngine, keyword, resultTitle, SupportService.stripHtmlTags(resultPreview), resultLink]);
    })

    return data;
  }

  static exportPosts = ({posts = []}) => {
    const data = [
      [
        i18n.t('Social Network'),
        i18n.t('Profile ID'),
        i18n.t('Published Date'),
        i18n.t('Text'),
        i18n.t('Shared Text'),
        i18n.t('External Links'),
        i18n.t('Images'),
        i18n.t('Videos'),
        i18n.t('Likes'),
        i18n.t('Comments'),
        i18n.t('Reactions'),
        i18n.t('Link')
      ],
    ];

    posts?.forEach(({socialNetwork, profileId, timeStamp, text, sharedText, imagesUrl, externalLinkUrl, videosUrl, likeCount, commentsCount, totalReactionsCount, postUrl}) => {
      data.push([socialNetwork, profileId, moment(timeStamp).format("YYYY/MM/DD HH:mm"), text, sharedText, externalLinkUrl?.join("\n"),  imagesUrl.join("\n"), videosUrl.join("\n"), likeCount, commentsCount, totalReactionsCount, postUrl]);
    })

    return data;
  }

  static exportPreferences = ({preferences: storedPreferences}) => {
    const data = [
      [i18n.t('Social Network'), i18n.t('Type'), i18n.t('Name'), i18n.t('URL')],
    ];

    storedPreferences?.forEach(({sid, preferences}) => {
      preferences?.forEach(({title, items}) => {
        items?.forEach(({subTitle, url}) => {
          data.push([sid, title, subTitle, url]);
        })
      })

    })

    return data;
  }

  static exportContactInfo = ({contactInfo: storedContactInfo}) => {
    const data = [
      [i18n.t('Social Network'), i18n.t('Type'), i18n.t('Name'), i18n.t('Value')],
    ];

    storedContactInfo?.forEach(({sid, contactInfo}) => {
      contactInfo?.forEach(({title, items}) => {
        items?.forEach(({subTitle, info}) => {
          data.push([sid, title, subTitle, info]);
        })
      })

    })

    return data;
  }

  static exportDeepWeb = ({deepWeb}) => {
    const data = [[
      i18n.t('Keyword'),//private String keyword;
      i18n.t('Type'),//private CrawlerType type;
      i18n.t('Created Date'),//private String createdDate;
      i18n.t('Address'),//private String address;
      i18n.t('Country'),//private String country;
      i18n.t('Date of birth'),//private String dateOfBirth;
      i18n.t('District'),//private String district;
      'Email',//private String email;
      i18n.t('Facebook ID'),//private String facebookId;
      i18n.t('Facebook Username'),//private String facebookUserName;
      i18n.t('Favorite word'),//private String favoriteWord;
      i18n.t('First Name'),//private String firstNameHydra;
      i18n.t('Last Name'),//private String lastNameHydra;
      i18n.t('Gender'),//private String gender;
      i18n.t('Linkedin Username'),//private String linkedinUserName;
      i18n.t('Password'),//private String password;
      i18n.t('Phone'),//private String phone;
      i18n.t('Profession'),//private String profession;
      'SSN',//private String ssn;
      i18n.t('Telegram ID'),//private String telegramId;
      i18n.t('Telegram Username'),//private String telegramUsername;
      i18n.t('Telegram Nickname'),//private String telegramNickname;
      i18n.t('VK ID'),//private String vkId;
    ]]

    deepWeb?.hydraNewData?.forEach(({display: item}) => {
      data.push([
        item?.keyword,
        item?.type,
        item?.createdDate,
        item.address,
        item.country,
        item.dateOfBirth,
        item.district,
        item.email,
        item.facebookId,
        item.facebookUserName,
        item.favoriteWord,
        item.firstNameHydra,
        item.lastNameHydra,
        item.gender,
        item.linkedinUserName,
        item.password,
        item.phone,
        item.profession,
        item.ssn,
        item.telegramId,
        item.telegramUsername,
        item.telegramNickname,
        item.vkId,
      ]);
    })

    return data;
  }
}